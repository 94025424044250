import { UserRoleDto } from '@digitalpharmacist/role-service-client-axios';

// This guard checks if a user has an "NO_ACCESS" role. Returns true if yes and false otherwise.
export const NoAccessUserGuard = (
  userRoles?: UserRoleDto[],
): Promise<boolean> =>
  new Promise((resolve) => {
    if (userRoles) {
      const hasAccess = !userRoles.find(
        (userRole) => userRole.role === 'NO_ACCESS',
      );

      resolve(hasAccess);
    } else {
      resolve(false);
    }
  });
